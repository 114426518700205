import React, { useState } from 'react';
import HeaderBar from '../common/HeaderBar';
import styles from './HomePage.module.css';
import ContactModal from '../common/ContactModal';
import FooterBar from '../common/FooterBar';


const HomePage = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleLinkClick = () => {
    setIsModalOpen(true);
  };

  const handleNotifyMe = (event) => {
    setIsSubscribed(true);
    //navigate('/35lz6vm87q425vd9-success');
    /* TODO: Open this once backend API implemented.
    event.preventDefault();
    fetch('/notify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      }); 
      */
  };


  const menuItems = [
    { type: 'link', label: 'Developers', href: '#' },
    { type: 'link', label: 'About', href: '#' },
    { type: 'link', label: 'Contact', href: '#', onClick: () => handleLinkClick() },
  ];

  return (
    <div className={styles.homePage}>
        <HeaderBar menuItems={menuItems} />
      <div className={styles.mazeBackground}></div>
      <div className={styles.container}>
        {isSubscribed 
        ?
        <div className={styles.header}>
        <h1>Thank you!</h1>
        <p>Thank you for subscribing! You will receive updates from us soon.</p>
        </div> 
        : 
        <>
        <div className={styles.header}>
        <h1>Stay Tuned...</h1>
        <p>
          Exciting Updates Coming Soon! Stay in the loop and be the first to
          know about our upcoming launch!!
        </p>
      </div>

      <div className={styles.formContainer}>
          <form onSubmit={handleNotifyMe}>
            <div className={styles.inputGroup}>
              <input
                type="email"
                name="email"
                placeholder="Email address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button type="submit" className={styles.notifyButton}>
                Notify me
              </button>
            </div>
          </form>
        </div>
      </>
        }
        
        <FooterBar/>
      </div>
      {isModalOpen && <ContactModal onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};

export default HomePage;
